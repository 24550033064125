import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { DataService } from 'app/shared/data.service';
import { AttachedLink } from 'app/state/abstract/base.repository';
const UrlReg = /^(?:https?:\/\/|\/\/)(?=\S*$)/;

@Component({
  selector: 'app-attach-links',
  templateUrl: './attach-links.component.html',
  styleUrls: ['./attach-links.component.scss'],
})
export class AttachLinksComponent implements OnInit {
  @Output() submit = new EventEmitter<Partial<AttachedLink>[]>();
  @Output() onAttachedLinkAdded = new EventEmitter<Partial<AttachedLink>>();
  @Output() onAttachedLinkRemoved = new EventEmitter<number>();
  @Input() docList: Partial<AttachedLink>[] = [];
  docForm: UntypedFormBuilder | any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dataService: DataService,
    private blobStorage: BlobStorageService
  ) {}

  ngOnInit(): void {
    this.docForm = this.formBuilder.group({
      text: [null],
      url: [null],
      documents: this.formBuilder.array([]),
    });
    this.setInitialDocs();
  }

  addLink() {
    var url = this.docForm.get('url').value;
    let hasError = false;
    if (!url || !UrlReg.test(url)) {
      this.docForm.controls.url.setErrors({ invalid: true });
      this.docForm.updateValueAndValidity();
      hasError = true;
    }
    var text = this.docForm.get('text').value;
    if (!text) {
      this.docForm.controls.text.setErrors({ invalid: true });
      this.docForm.updateValueAndValidity();
      hasError = true;
    }
    if (hasError) return;

    let control = <UntypedFormArray>this.docForm?.controls.documents;

    if (url && text && control) {
      control.push(
        this.formBuilder.group({
          id: [],
          text: [text],
          url: [url],
        })
      );
      this.docForm.controls.url.setErrors();
      this.docForm.controls.text.setErrors();

      <UntypedFormArray>this.docForm?.controls.url.setValue(null);
      <UntypedFormArray>this.docForm?.controls.text.setValue(null);

      const attachedLink: Partial<AttachedLink> = { text, url };
      this.onAttachedLinkAdded.emit(attachedLink);
    }
  }

  setInitialDocs() {
    let control = <UntypedFormArray>this.docForm?.controls.documents;
    this.docList.forEach((x, index) => {
      control.push(
        this.formBuilder.group({
          id: [x.id],
          text: [x.text],
          url: [x.url],
        })
      );
    });
  }
  getControls() {
    if (this.docForm) {
      return (this.docForm.get('documents') as UntypedFormArray).controls;
    }
    return [];
  }

  deleteFile(index: number) {
    let control = this.getControls();
    if (index > -1) {
      control.splice(index, 1);
    }
    this.onAttachedLinkRemoved.emit(index);
  }
}
