<div class="docform">
  <div class="toolbar">
    <form [formGroup]="inputForm" enctype="multipart/form-data">
      <div class="row">
        <div
          class="form-floating col-5"
          [class.is-invalid]="inputForm.get('url').errors"
        >
          <div class="inpt-title">{{ "URL" | transloco }}</div>
          <input
            id="url"
            formControlName="url"
            type="text"
            class="inpt"
            placeholder="{{ 'URL' | transloco }}"
            autocomplete="off"
          />
        </div>
        <div
          class="form-floating col-5"
          [class.is-invalid]="inputForm.get('text').errors"
        >
          <div class="inpt-title">{{ "text" | transloco }}</div>
          <input
            id="text"
            formControlName="text"
            type="text"
            class="inpt"
            placeholder="{{ 'text' | transloco }}"
            autocomplete="off"
          />
        </div>
        <div class="form-floating col-2" style="padding-top: 20px">
          <button class="btn btn-orange" (click)="addLink()">Add</button>
        </div>
      </div>
    </form>
    <div class="mainfont imgfont ms-1 mb-2">
      <div *ngFor="let link of links?.controls; let i = index">
        <div class="d-flex align-items-center">
          <span class="linkLine">
            {{ link.value.text ?? link.value.url }}
          </span>

          <img
            class="ps-2 cursor-pointer"
            (click)="deleteLink(i)"
            src="assets/img/trash.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
