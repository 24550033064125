import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { DataService } from 'app/shared/data.service';

@Component({
  selector: 'app-attach-links-as-form',
  templateUrl: './attach-links-as-form.component.html',
  styleUrls: ['./attach-links-as-form.component.scss'],
})
export class AttachLinksAsFormComponent {
  @Input() linksForm: FormGroup | any;
  inputForm: UntypedFormBuilder | any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dataService: DataService,
    private blobStorage: BlobStorageService
  ) {}

  get links() {
    return this.linksForm.get('attachedLinks') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.inputForm = this.formBuilder.group({
      text: [null],
      url: [null],
    });
    if (!this.links) {
      this.linksForm.addControl('attachedLinks', this.formBuilder.array([]));
    }
  }

  deleteLink(index: number) {
    this.links?.removeAt(index);
  }

  addLink() {
    var url = this.inputForm.get('url').value;
    if (!url) {
      this.inputForm.controls.url.setErrors({ invalid: true });
      this.inputForm.updateValueAndValidity();
    }

    var text = this.inputForm.get('text').value;
    if (!text) {
      this.inputForm.controls.text.setErrors({ invalid: true });
      this.inputForm.updateValueAndValidity();
    }

    if (url && text) {
      const newLink = this.formBuilder.group({
        id: [],
        text: [text],
        url: [url],
      });

      this.links?.push(newLink);

      this.inputForm.controls.url.setErrors();
      this.inputForm.controls.text.setErrors();
      this.resetForm();
    }
  }

  resetForm() {
    <UntypedFormArray>this.inputForm?.controls.url.setValue(null);
    <UntypedFormArray>this.inputForm?.controls.text.setValue(null);
  }
}
